export function getMaxHeight(el?: HTMLElement): number {
	if (!el) return 0

	const { y } = useWindowScroll()

	const elTop = el.offsetTop
	const elBottom = elTop + el.offsetHeight

	const winTop = y.value
	const winBottom = winTop + window.innerHeight

	const top = winTop < elTop ? elTop - winTop : 0
	const bottom = winBottom > elBottom ? winBottom - elBottom : 0

	return window.innerHeight - (top + bottom)
}
