<template>
	<div ref="layoutEl" class="sidebar container">
		<main aria-label="Content" class="sidebar__main">
			<slot name="main" />
		</main>

		<aside aria-label="Sidebar" class="sidebar__aside">
			<div
				class="sidebar__sticky"
				:style="{
					'max-height': maxHeight ? `${maxHeight}px` : 'none',
				}">
				<slot name="aside" />
			</div>
		</aside>
	</div>
</template>

<script lang="ts" setup>
	import { getMaxHeight } from '~/utilities/max-height'

	const layoutEl = ref<HTMLElement>()

	const maxHeight = computed(() => getMaxHeight(layoutEl.value))
</script>

<style lang="scss">
	.sidebar {
		position: relative;
		height: 100%;

		@include media-breakpoint-up('lg') {
			display: grid;
			grid-template-rows: auto;
			grid-template-columns: 250px minmax(0, 1fr);
			grid-template-areas: 'aside main';
		}

		&__aside {
			display: none;

			@include media-breakpoint-up('lg') {
				display: block;
				grid-area: aside;
				border-right: 1px solid $gray-300;
			}
		}

		&__sticky {
			overflow: auto;
			position: sticky;
			top: 0px;
			z-index: 1;
		}

		&__main {
			@include media-breakpoint-up('lg') {
				grid-area: main;
			}
		}
	}
</style>
